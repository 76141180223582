import { createContext, useState } from 'react';

export const DirectRequestDialogContext = createContext();

// This context is needed to for the BottomCTA component to be able to open it
export const DirectRequestDialogProvider = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  return (
    <DirectRequestDialogContext.Provider
      value={{
        dialogOpen,
        openDialog,
        closeDialog,
      }}>
      {children}
    </DirectRequestDialogContext.Provider>
  );
};
