import countryCodes from 'country-codes-list';
import { format as formatDateFns } from 'date-fns';
import parsePhoneNumber, {
  findPhoneNumbersInText,
} from 'libphonenumber-js/max';

const countryCodesList = countryCodes.customList(
  'countryCode',
  `+{countryCallingCode}`
);

export const formatDate = (date, hyphens) => {
  if (!date) {
    return '';
  }
  return hyphens
    ? formatDateFns(date, 'yyyy-MM-dd')
    : formatDateFns(date, 'dd.MM.yyyy');
};

export const calculateHeatingPower = data => {
  const {
    averageHeight: h,
    totalArea: a,
    isolation: i,
    indoorTemperature: ti,
    outdoorTemperature: ta,
  } = data;
  // in the app, `,` is used for the floating/decimal values. Therefore,
  // they need to be replaced with `.`s.
  const dimensions = [a, h].reduce(
    (acc, v) => acc * Number(v.replace(',', '.')),
    1
  );
  return String(Math.ceil((dimensions * i * (ti - ta) * 1.16) / 1000));
};

export const generateUUID = () => {
  const suffix = Array.from([1, 2, 3], el =>
    Math.round(Math.random() * el)
  ).join('');
  return `${new Date().getTime()}-${suffix}`;
};

export const formatPhoneNumber = (countryCode, phone) =>
  `${countryCodesList[countryCode]}${phone}`;

export const updatePhoneNumbers = () => {
  const parserFunc = window.parsePhoneNumbers;
  const isFuncLoaded = parserFunc instanceof Function;

  if (isFuncLoaded) {
    parserFunc();
  }
};

export const isEmpty = o => Object.keys(o).length === 0;

export const getDate = day => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  if (day === 'today') {
    return today;
  } else if (day === 'tomorrow') {
    return tomorrow;
  }
  return '';
};

export const formatBookingDate = date => {
  if (!date) {
    return '';
  }
  const d = new Date(date);
  return formatDate(d);
};

export const filterEmptyProperties = (obj = {}) => {
  const objCopy = { ...obj };

  Object.keys(objCopy).forEach(key => {
    if (objCopy[key] === null || objCopy[key] === undefined) {
      delete objCopy[key];
    }
  });
  return objCopy;
};

export const formatPhoneToLink = (phoneNumber, countryCode = 'DE') => {
  const { number } = parsePhoneNumber(phoneNumber, countryCode);
  return `tel:${number}`;
};

export const getPhoneFromLink = link => {
  if (!link) {
    return null;
  }
  const phoneNumbers = findPhoneNumbersInText(link);
  if (phoneNumbers.length) {
    const { number } = phoneNumbers[0];
    return number.number;
  } else {
    return null;
  }
};

export const removeMachineNameFromAccessoryName = accessoryName => {
  if (typeof accessoryName !== 'string') {
    return '';
  }

  const lastIndex = accessoryName.lastIndexOf(' für ');
  if (lastIndex > -1) {
    return accessoryName.slice(0, lastIndex);
  } else {
    return accessoryName;
  }
};

export const getBookingRentalEndLabel = ({
  opportunityStage,
  rentalEndDate,
  formatMessage,
}) =>
  opportunityStage === 'Ausgeliefert'
    ? formatMessage('cp_bookings_rental_end_open')
    : formatBookingDate(rentalEndDate);

export function stripHtmlTags(html) {
  const regex = /<[^>]*>/g;
  return html.replace(regex, '');
}

export function pascalCaseToSnakeCase(text = '') {
  return text
    .replace(/([A-Z])/g, '_$1')
    .toLowerCase()
    .slice(1);
}
