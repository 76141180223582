import de from './de.json';
import en from './en.json';

export const LOCALES = {
  GERMAN: 'de',
  ENGLISH: 'en',
};

export const MESSAGES = {
  [LOCALES.GERMAN]: de,
  // fallback translations in German
  [LOCALES.ENGLISH]: { ...de, ...en },
};
