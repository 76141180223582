import cloneDeep from 'lodash.clonedeep';
import setObjectPropValue from 'lodash.set';
import { createContext, useState } from 'react';

import useFormatMessage from '@/hooks/useFormatMessage';

export const GenericContactFormContext = createContext();

const initialData = {
  forename: '',
  surname: '',
  company: '',
  phone: {
    countryCode: 'DE',
    number: '',
  },
  email: '',
  message: '',
  marketingConsent: false,
};

export const GenericContactFormProvider = ({ children }) => {
  const formatMessage = useFormatMessage();
  const defaultEmail = formatMessage('header_email');
  const defaultSubject = formatMessage('header_email_subject');
  const defaultBody = formatMessage('header_email_body');

  const [contactFormData, setContactFormData] = useState(initialData);
  const [email, setEmail] = useState(defaultEmail);
  const [emailSubject, setEmailSubject] = useState(defaultSubject);
  const [emailBody, setEmailBody] = useState(defaultBody);

  const resetContactFormData = () => {
    setContactFormData(initialData);
    setEmail(defaultEmail);
    setEmailSubject(defaultSubject);
    setEmailBody(defaultBody);
  };

  const updateContactFormFieldData = (fieldName, value) => {
    // fieldName can use dot notation for example phone.number
    setContactFormData(prev => {
      const updatedPrev = cloneDeep(prev);
      setObjectPropValue(updatedPrev, fieldName, value);
      return updatedPrev;
    });
  };

  const setContactFormDataWithDefaults = newData => {
    setContactFormData({ ...initialData, ...newData });
  };

  return (
    <GenericContactFormContext.Provider
      value={{
        contactFormData,
        email,
        emailSubject,
        emailBody,
        resetContactFormData,
        updateContactFormFieldData,
        setContactFormData: setContactFormDataWithDefaults,
        setEmail,
        setEmailSubject,
        setEmailBody,
      }}>
      {children}
    </GenericContactFormContext.Provider>
  );
};
