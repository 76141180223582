import { createContext, useCallback, useState } from 'react';

export const HeatingAssistantContext = createContext();

const initialData = {
  activeStep: 0,
  flow: 'direct',
  power: '',
  userPower: '',
  heatingType: {
    oil: false,
    gas: false,
    electro: false,
  },
  rooms: '',
  totalArea: '',
  averageHeight: '',
  isolation: null,
  dateFrom: null,
  dateTo: null,
  indoorTemperature: 15,
  outdoorTemperature: 0,
  gender: 'male',
  name: '',
  surname: '',
  company: '',
  crefo: null,
  addNonCrefoCompany: false,
  nonCrefoCompanyName: '',
  nonCrefoCompanyAddress: {
    street: '',
    postalCode: '',
    city: '',
    country: { label: 'Deutschland', value: 'Deutschland' },
  },
  countryCode: 'DE',
  phone: '',
  email: '',
  deliveryAddress: {
    street: '',
    postalCode: '',
    city: '',
    country: { label: 'Deutschland', value: 'Deutschland' },
  },
  marketingConsent: false,
};

export const HeatingAssistantProvider = ({ children }) => {
  const [data, setData] = useState(initialData);

  const resetHeatingAssistantData = useCallback(() => setData(initialData), []);

  const updateHeatingAssistantData = useCallback(
    data => setData(prev => ({ ...prev, ...data })),
    []
  );

  return (
    <HeatingAssistantContext.Provider
      value={{
        heatingAssistantData: data,
        resetHeatingAssistantData,
        updateHeatingAssistantData,
      }}>
      {children}
    </HeatingAssistantContext.Provider>
  );
};
