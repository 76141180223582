import _getConfig from 'next/config';

export default function getConfig() {
  const configs = _getConfig();

  if (configs) {
    return configs;
  }

  // mock for development
  return {
    publicRuntimeConfig: {
      APP_ENV: 'sandbox',
    },
    serverRuntimeConfig: {},
  };
}
