import { createContext, useState } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import { LOCALES, MESSAGES } from '@/i18n/constants';

export const LocaleContext = createContext();

export const getLocale = acceptLanguages => {
  if (!acceptLanguages?.length) {
    return LOCALES.GERMAN;
  }

  const preferredLanguage = acceptLanguages[0];
  return preferredLanguage === 'de' || preferredLanguage.startsWith('de-')
    ? LOCALES.GERMAN
    : LOCALES.ENGLISH;
};

export const IntlProvider = ({ children, acceptLanguages }) => {
  const [currentLocale, setCurrentLocale] = useState(
    getLocale(acceptLanguages)
  );

  return (
    <LocaleContext.Provider value={{ currentLocale, setCurrentLocale }}>
      <ReactIntlProvider
        defaultLocale={LOCALES.GERMAN}
        locale={currentLocale}
        messages={MESSAGES[currentLocale]}
        key={currentLocale}
        textComponent="span">
        {children}
      </ReactIntlProvider>
    </LocaleContext.Provider>
  );
};
