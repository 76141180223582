import SvgIconMaterial from '@mui/material/SvgIcon';

import icons from './icons';

export default function SvgIcon({ name, ...props }) {
  const iconComponent = icons[name];

  if (!iconComponent) {
    return null;
  }
  return <SvgIconMaterial component={iconComponent} {...props} />;
}
