import { createContext, useRef, useState } from 'react';

export const BasketPreviewContext = createContext();

export const BasketPreviewProvider = ({ children }) => {
  // state refs used for setTimeout to have up to date value
  const [open, setOpen] = useState(false);
  const openRef = useRef(open);
  openRef.current = open;

  const [basketPreviewHovered, setBasketPreviewHovered] = useState(false);
  const basketPreviewHoveredRef = useRef(basketPreviewHovered);
  basketPreviewHoveredRef.current = basketPreviewHovered;

  const [closeTimeoutId, setCloseTimoutId] = useState(null);

  const openBasketPreview = ({ event, closeTimeout } = {}) => {
    // event - the event source of the callback
    // stopping event propagation so the basket preview is not immediately closed
    event?.stopPropagation();
    setOpen(true);

    if (typeof closeTimeout === 'number') {
      const timoutId = setTimeout(() => {
        if (openRef.current && !basketPreviewHoveredRef.current) {
          setOpen(false);
        }
        setCloseTimoutId(null);
      }, closeTimeout);

      setCloseTimoutId(timoutId);
    }
  };

  const closeBasketPreview = () => {
    if (open) {
      clearCloseTimeout();
      setOpen(false);
    }
  };

  const clearCloseTimeout = () => {
    if (closeTimeoutId) {
      clearTimeout(closeTimeoutId);
      setCloseTimoutId(null);
    }
  };

  return (
    <BasketPreviewContext.Provider
      value={{
        openBasketPreview,
        closeBasketPreview,
        setBasketPreviewHovered,
        basketPreviewOpen: open,
      }}>
      {children}
    </BasketPreviewContext.Provider>
  );
};
