import Head from 'next/head';
import PropTypes from 'prop-types';
import React from 'react';

import useFormatMessage from '@/hooks/useFormatMessage';
import packageJson from '@/package.json';

const PageHead = ({ baseUrl, canonicalUrl }) => {
  const formatMessage = useFormatMessage();

  return (
    <Head>
      <title>{formatMessage('default_metadata_title')}</title>
      <meta name="version" content={packageJson.version} />
      <meta
        key="description"
        name="description"
        content={formatMessage('default_metadata_description')}
      />
      <meta charSet="utf-8" />
      <meta name="theme-color" content="#0171B7" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta property="og:url" content={canonicalUrl} key="og:url" />
      <meta property="og:type" content="website" key="og:type" />
      <meta
        property="og:title"
        content={formatMessage('default_metadata_title')}
        key="og:title"
      />
      <meta
        property="og:description"
        content={formatMessage('default_metadata_description')}
        key="og:description"
      />
      <meta
        property="og:image"
        content={`${baseUrl}/images/social.png`}
        key="og:image"
      />

      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twitter:card"
      />
      <meta
        name="twitter:image"
        content={`${baseUrl}/images/social-twitter.png`}
        key="twitter:image"
      />
      <link rel="canonical" href={canonicalUrl} />
      <link rel="icon" href="/favicon.ico" />
      <link rel="manifest" href="/manifest.json" />
      <base href={baseUrl} />
      <link
        rel="preload"
        href="/fonts/Roboto-Regular.ttf"
        as="font"
        crossOrigin=""
        type="font/ttf"
      />
      <link
        rel="preload"
        href="/fonts/Roboto-Medium.ttf"
        as="font"
        crossOrigin=""
        type="font/ttf"
      />
      <link
        rel="preload"
        href="/fonts/Roboto-MediumItalic.ttf"
        as="font"
        crossOrigin=""
        type="font/ttf"
      />
      <link
        rel="preload"
        href="/fonts/Roboto-Bold.ttf"
        as="font"
        crossOrigin=""
        type="font/ttf"
      />
      <link
        rel="preload"
        href="/fonts/Roboto-Black.ttf"
        as="font"
        crossOrigin=""
        type="font/ttf"
      />
      <link
        rel="preload"
        href="/fonts/MaterialIcons-Regular.ttf"
        as="font"
        crossOrigin=""
        type="font/ttf"
      />
    </Head>
  );
};

PageHead.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  canonicalUrl: PropTypes.string.isRequired,
};

export default PageHead;
