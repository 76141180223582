import { useRouter } from 'next/router';
import { createContext, useEffect, useState } from 'react';

import Loader from '@/components/ui/Loader/Loader';
import api from '@/helpers/api';
import { customerPortalPage } from '@/helpers/customerPortal';

export const AuthContext = createContext();

const getToken = async token => {
  const oldToken = localStorage.getItem('token');

  if (!oldToken && !token) return false;

  if (token && token !== oldToken) {
    try {
      const { status } = await api.checkToken(token);
      if (status && status === 204) {
        localStorage.setItem('token', token);
        return token;
      }
    } catch (err) {
      /* continue regardless of error */
    }
  }

  try {
    const { status: oldTokenStatus } = await api.checkToken(oldToken);

    if (oldTokenStatus && oldTokenStatus === 204) {
      return oldToken;
    }

    return false;
  } catch (err) {
    return false;
  }
};

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loggingOut, setLoggingOut] = useState(false);
  const router = useRouter();
  const { query, pathname } = router;

  const clearAuthentication = () => {
    api.purge();
    setAuthenticated(false);
  };

  const logOut = async () => {
    clearAuthentication();
    if (customerPortalPage(pathname)) {
      setLoggingOut(true);
      await router.replace('/');
      setLoggingOut(false);
    }
  };

  useEffect(() => {
    async function checkAuth() {
      try {
        const tokenFromQuery = query && query.token;
        const token = await getToken(tokenFromQuery);

        const hasToken = !!token;

        if (hasToken) {
          setAuthenticated(true);
          api.updateToken(token);
        }
      } catch {
        // ignore error
      } finally {
        setLoading(false);
      }
    }
    checkAuth();
  }, []);

  return (
    <AuthContext.Provider
      value={{ authenticated, logOut, loading, clearAuthentication }}>
      <Loader fullPage show={loggingOut} />
      {children}
    </AuthContext.Provider>
  );
};
