import { computeDate } from './mappers/rentalRequests';
import { generateUUID } from './utils';

const pushToDataLayer = event => {
  const dataLayer = window.dataLayer || (window.dataLayer = []);

  if (!window.gtag) {
    window.gtag = function gtag() {
      dataLayer.push(arguments);
    };
  }

  dataLayer.push(event);

  return dataLayer;
};

export const pageView = ({ title }) => {
  const url = window.location.origin + window.location.pathname;

  return pushToDataLayer({
    event: 'Pageview',
    pagePath: url,
    pageTitle: title,
  });
};

export const trackHeatingAddProduct = (data, leadReference) => {
  const heatingProducts = ['oil', 'electro', 'gas']
    .filter(type => data.heatingType[type] === true)
    .map(type => ({
      item_id: 'freeText',
      item_name: type,
      item_category: leadReference,
      quantity: 1,
    }));

  return pushToDataLayer({
    event: 'add_to_cart',
    ecommerce: {
      items: heatingProducts,
    },
  });
};

export const trackHeatingPurchase = (data, leadReference) => {
  const purchaseIdPrefix = () => {
    const { flow } = data;
    if (flow === 'direct') {
      return 'heating-anfrage_';
    } else if (flow === 'wizard') {
      return 'heating-rechner_';
    }
    return '';
  };

  const dateStart = computeDate(data.dateFrom);
  const dateEnd = computeDate(data.dateTo);
  const variant = `${dateStart}-${dateEnd}`;

  const heatingProducts = ['oil', 'electro', 'gas']
    .filter(type => data.heatingType[type] === true)
    .map(type => ({
      item_id: 'freeText',
      item_name: type,
      price: '0',
      item_brand: 'unknown',
      item_category: leadReference,
      variant,
      quantity: 1,
    }));

  return pushToDataLayer({
    event: 'purchase',
    ecommerce: {
      transaction_id: `${purchaseIdPrefix()}${generateUUID()}`,
      items: heatingProducts,
      affiliation: 'onlineshop',
    },
  });
};

export const trackDirectPurchase = (data, voucher, leadReference) => {
  const products = data.map(product => {
    const dateStart = computeDate(product.dateFrom);
    const dateEnd = computeDate(product.dateTo);
    const variant = `${dateStart}-${dateEnd}`;
    const accessories = product.accessories
      .filter(({ count }) => count > 0)
      .map(({ itemData, count }) => ({
        item_id: itemData.salesforceId || 'freeText',
        item_name: itemData.name,
        quantity: count,
      }));

    return {
      item_id: product.rentalObject.salesforceId || 'freeText',
      item_name: product.rentalObject.name,
      price: '0',
      item_brand: 'unknown',
      item_category: product.rentalObject.productGroupName || leadReference,
      variant,
      quantity: product.count,
      accessories,
    };
  });

  return pushToDataLayer({
    event: 'purchase',
    ecommerce: {
      transaction_id: generateUUID(),
      items: products,
      affiliation: 'onlineshop',
      coupon: voucher || null,
    },
  });
};

export const trackAddToBasket = (addedProducts = [], leadReference) => {
  const products = [];
  addedProducts.forEach(product => {
    products.push({
      item_id: product.rentalObject.salesforceId || 'freeText',
      item_name: product.rentalObject.name,
      item_category: product.rentalObject.productGroupName || leadReference,
      quantity: product.count,
    });
  });

  return pushToDataLayer({
    event: 'add_to_cart',
    ecommerce: {
      items: products,
    },
  });
};

export const trackAccountFormSubmit = () =>
  pushToDataLayer({ event: 'account_update_button' });

export const trackAccountCompany = () =>
  pushToDataLayer({ event: 'account_company_select' });

export const trackAccountUser = () =>
  pushToDataLayer({ event: 'account_user_select' });

export const trackOwnerAccept = () =>
  pushToDataLayer({ event: 'owner_accept_button' });

export const trackOwnerDecline = () =>
  pushToDataLayer({ event: 'owner_decline_button' });

/* Events tracking for Customer Portal */
export const trackCustomerPortalFilterSelect = () =>
  pushToDataLayer({ event: 'cp_oppoverview_filter' });

export const trackCustomerPortalOpportunityDetail = () =>
  pushToDataLayer({ event: 'cp_oppoverview_oppdetail' });

export const trackCustomerPortalRentAgain = () =>
  pushToDataLayer({ event: 'cp_oppdetail_rentagain' });

export const trackCustomerPortalMachineRelease = () =>
  pushToDataLayer({ event: 'cp_oppdetail_machinerelease_dialogue' });

export const trackCustomerPortalMachineReleaseConfirm = () =>
  pushToDataLayer({ event: 'cp_oppdetail_machinerelease_confirm' });

export const trackCustomerPortalInvoicesTabView = () =>
  pushToDataLayer({ event: 'cp_oppdetail_navigatetoinvoices' });

export const trackCustomerPortalInvoiceView = () =>
  pushToDataLayer({ event: 'cp_invoices_viewinvoice' });

export const trackCustomerPortalInvoiceDownload = () =>
  pushToDataLayer({ event: 'cp_invoices_downloadinvoice' });

export const trackCustomerPortalMachinesTabView = () =>
  pushToDataLayer({ event: 'cp_oppdetail_navigatetomachines' });

/* Events tracking for navigation elements */
export const trackMenuItemClick = () =>
  pushToDataLayer({ event: 'navigation_elements', location: 'menu' });

export const trackBreadcrumbClick = () =>
  pushToDataLayer({ event: 'navigation_elements', location: 'breadcrumb' });

export const trackFAQClick = () =>
  pushToDataLayer({ event: 'navigation_elements', location: 'faq' });

export const trackProductTileClick = () =>
  pushToDataLayer({ event: 'navigation_elements', location: 'category_cards' });

/* Events tracking for request elements */
export const trackRequestNewsletter = () =>
  pushToDataLayer({ event: 'request_newsletter', location: 'footer' });

export const trackBasketSubmit = () =>
  pushToDataLayer({ event: 'request_button', location: 'basket_next' });

export const trackBottomCTAButtonClick = () =>
  pushToDataLayer({ event: 'request_button', location: 'scroll' });

export const trackFloatingCTAButtonClick = () =>
  pushToDataLayer({ event: 'request_button', location: 'floating' });

/* Events tracking for calls */
export const trackHeaderPhoneClick = () =>
  pushToDataLayer({ event: 'request_call', location: 'header' });

export const trackMobilePhoneClick = () =>
  pushToDataLayer({ event: 'request_call', location: 'menu_mobile' });

export const trackContactBoxPhoneClick = () =>
  pushToDataLayer({ event: 'request_call', location: 'contact_box' });

/* Events tracking for emails */
export const trackContactBoxEmailClick = () =>
  pushToDataLayer({ event: 'request_email', location: 'contact_box' });

export const trackHeaderEmailClick = () =>
  pushToDataLayer({ event: 'request_email', location: 'header' });

export const trackContactFormEmailClick = () =>
  pushToDataLayer({ event: 'request_email', location: 'contact_form' });

export const trackContactFormSuccess = () =>
  pushToDataLayer({ event: 'request_email', location: 'contact_form_success' });

export const trackMenuMobileEmailClick = () =>
  pushToDataLayer({ event: 'request_email', location: 'menu_mobile' });

export const trackContainerPageRequestEmail = () =>
  pushToDataLayer({ event: 'request_email', location: 'request_now' });

export const trackBecomePartnerClick = () =>
  pushToDataLayer({ event: 'request_email', location: 'partner' });

export const trackBottomCTAButtonEmailClick = () =>
  pushToDataLayer({ event: 'request_email', location: 'scroll' });

export const trackCountdownBannerCTA = () =>
  pushToDataLayer({ event: 'campaign_banner', location: 'fall_sale_2024' });
