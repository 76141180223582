/* eslint-disable react/display-name */
import React from 'react';

export const elements = {
  br: <br />,
  span: chunks => <span>{chunks}</span>,
  b: chunks => <b>{chunks}</b>,
  p: chunks => <p>{chunks}</p>,
  div: chunks => <div>{chunks}</div>,
  h2: chunks => <h2>{chunks}</h2>,
  u: chunks => <u>{chunks}</u>,
};

export const getParsedAcceptLangs = acceptLangsHeader => {
  if (!acceptLangsHeader || typeof acceptLangsHeader !== 'string') {
    return null;
  }

  const pairs = acceptLangsHeader.split(',');
  const result = [];
  pairs.forEach(pair => {
    result.push(pair.split(';')[0]);
  });
  return result;
};
