import cloneDeep from 'lodash.clonedeep';
import setObjectPropValue from 'lodash.set';
import { createContext, useEffect, useState } from 'react';

export const OrderDetailsFormContext = createContext();

export const initialData = {
  gender: 'male',
  forename: '',
  surname: '',
  phone: {
    countryCode: 'DE',
    number: '',
  },
  email: '',
  company: {
    name: '',
    crefo: null,
  },
  addNonCrefoCompany: false,
  nonCrefoCompanyName: '',
  nonCrefoCompanyAddress: {
    street: '',
    postalCode: '',
    city: '',
    country: { label: 'Deutschland', value: 'Deutschland' },
  },
  deliveryLocation: {
    street: '',
    postalCode: '',
    city: '',
    country: { label: 'Deutschland', value: 'Deutschland' },
  },
  delivery: true,
  technicianContactSameAsPrimary: false,
  technicianGender: 'male',
  technicianForename: '',
  technicianSurname: '',
  technicianPhone: {
    countryCode: 'DE',
    number: '',
  },
  voucher: '',
  consultationRequired: false,
  tos: false,
  marketingConsent: false,
};

const ORDER_DETAILS_STORAGE_KEY = 'orderDetails';

export const OrderDetailsFormProvider = ({ children }) => {
  const [orderDetailsData, setOrderDetailsData] = useState(initialData);
  const [dataHydrated, setHydrated] = useState(false);

  const resetOrderDetailsData = () => setOrderDetailsData(initialData);

  const saveOrderdDetailsDataInLocalStorage = data => {
    localStorage.setItem(ORDER_DETAILS_STORAGE_KEY, JSON.stringify(data));
  };

  const saveOrderDetailsData = () => {
    setOrderDetailsData(prev => {
      const data = {
        ...prev,
        consultationRequired: false,
        voucher: '',
        tos: false,
        marketingConsent: false,
      };
      saveOrderdDetailsDataInLocalStorage(data);
      return data;
    });
  };

  const updateOrderDetailsData = (fieldName, value) => {
    // fieldName can use dot notation for example phone.number
    setOrderDetailsData(prev => {
      const updatedPrev = cloneDeep(prev);
      setObjectPropValue(updatedPrev, fieldName, value);
      return updatedPrev;
    });
  };

  useEffect(() => {
    const savedOrderDetailsData = JSON.parse(
      localStorage.getItem(ORDER_DETAILS_STORAGE_KEY)
    );
    if (savedOrderDetailsData) {
      setOrderDetailsData(savedOrderDetailsData);
    }
    setHydrated(true);
  }, []);

  return (
    <OrderDetailsFormContext.Provider
      value={{
        orderDetailsData,
        updateOrderDetailsData,
        resetOrderDetailsData,
        saveOrderDetailsData,
        saveOrderdDetailsDataInLocalStorage,
        orderDetailsDataHydrated: dataHydrated,
      }}>
      {children}
    </OrderDetailsFormContext.Provider>
  );
};
