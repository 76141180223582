import axios from 'axios';

import getConfig from '@/configs/global';

const config = getConfig();
const { publicRuntimeConfig } = config;

const { DAMETIS_API_URL } = publicRuntimeConfig;

const dametisInstance = axios.create({ baseURL: DAMETIS_API_URL });

const dametisApi = {
  queryProductTypes: async (query = '', page = 1, only) => {
    const endpoint = '/v1/product-types';
    const params = {
      page,
      term: query,
      only,
    };

    return dametisInstance.get(endpoint, { params });
  },
  getProductTypesByGroup: async productGroupId => {
    const endpoint = `/v1/product-groups/${productGroupId}/product-types`;
    const params = { size: 40 };

    return dametisInstance.get(endpoint, { params });
  },
  queryAccessories: async ({
    query = '',
    productReferenceId,
    page = 1,
    size,
    exclude = null,
    include = null,
  }) => {
    const endpoint = '/v1/accessories';
    const params = {
      page,
      referenceProductTypeId: productReferenceId,
      term: query,
      size,
      exclude,
      include,
    };

    return dametisInstance.get(endpoint, { params });
  },
  getProductGroupQuestions: async ({
    productGroupId,
    size = 100,
    page = 1,
  }) => {
    const endpoint = `/v1/product-groups/${productGroupId}/questions`;
    return dametisInstance.get(endpoint, { params: { size, page } });
  },
  getProductsBySalesforceId: async salesforceIds => {
    if (Array.isArray(salesforceIds)) {
      return dametisInstance.get(
        `/v1/product-types/salesforce/${salesforceIds.join(',')}`
      );
    }
  },
};

export default dametisApi;
