import dynamic from 'next/dynamic';

const icons = {
  phone: dynamic(() => import('./lib/ui-icon-phone.svg')),
  mail: dynamic(() => import('./lib/ui-icon-mail.svg')),
  attention: dynamic(() => import('./lib/ui-icon-attention.svg')),
  shoppingCart: dynamic(() => import('./lib/ui-icon-shopping-cart.svg')),
  menu: dynamic(() => import('./lib/ui-icon-menu.svg')),
  close: dynamic(() => import('./lib/ui-icon-close.svg')),
  minus: dynamic(() => import('./lib/ui-icon-minus.svg')),
  plus: dynamic(() => import('./lib/ui-icon-plus.svg')),
  check: dynamic(() => import('./lib/ui-icon-check.svg')),
  calendar: dynamic(() => import('./lib/ui-icon-calendar.svg')),
  arrow: dynamic(() => import('./lib/ui-icon-arrow.svg')),
  trashcan: dynamic(() => import('./lib/ui-icon-trashcan.svg')),
  info: dynamic(() => import('./lib/ui-icon-info.svg')),
  edit: dynamic(() => import('./lib/ui-icon-edit.svg')),
  document: dynamic(() => import('./lib/ui-icon-document.svg')),
  arrowBack: dynamic(() => import('./lib/ui-icon-arrow-back.svg')),
  search: dynamic(() => import('./lib/ui-icon-search.svg')),
  resume: dynamic(() => import('./lib/ui-icon-resume.svg')),
  facebook: dynamic(() => import('./lib/facebook.svg')),
  linkedin: dynamic(() => import('./lib/linkedin.svg')),
};

export default icons;
