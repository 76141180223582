import { createContext, useCallback, useState } from 'react';

export const CraneWizardContext = createContext();

const initialData = {
  activeStep: 1,
  type: '',
  subType: '',
  hookHeight: '',
  distance: '',
  maxWeight: '',
  disturbanceDistance: '',
  disturbanceHeight: '',
  count: 1,
  gender: 'male',
  name: '',
  surname: '',
  company: '',
  crefo: null,
  addNonCrefoCompany: false,
  nonCrefoCompanyName: '',
  nonCrefoCompanyAddress: {
    street: '',
    postalCode: '',
    city: '',
    country: { label: 'Deutschland', value: 'Deutschland' },
  },
  countryCode: 'DE',
  phone: '',
  email: '',
  deliveryAddress: {
    street: '',
    postalCode: '',
    city: '',
    country: { label: 'Deutschland', value: 'Deutschland' },
  },
  dateFrom: null,
  dateTo: null,
  marketingConsent: false,
};

export const CraneWizardProvider = ({ children }) => {
  const [data, setData] = useState(initialData);

  const resetCraneWizardData = useCallback(() => setData(initialData), []);

  const updateCraneWizardData = useCallback(
    data => setData(prev => ({ ...prev, ...data })),
    []
  );

  return (
    <CraneWizardContext.Provider
      value={{
        craneWizardData: data,
        resetCraneWizardData,
        updateCraneWizardData,
      }}>
      {children}
    </CraneWizardContext.Provider>
  );
};
