import { CircularProgress, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { Backdrop } from './Loader.style';

/**
 * Loading indicator.
 */
const Loader = React.forwardRef(
  (
    { 'data-cy': dataCy, className, spinnerClassName, show, size, fullPage },
    ref
  ) => {
    if (fullPage) {
      return (
        <Backdrop
          open={show}
          className={className}
          ref={ref}
          componentsProps={{ root: { ['data-cy']: dataCy } }}>
          <CircularProgress
            size={size}
            thickness={5}
            color="primary"
            className={spinnerClassName}
          />
        </Backdrop>
      );
    }

    if (show) {
      return (
        <Stack
          justifyContent="center"
          alignItems="center"
          className={className}
          ref={ref}
          data-cy={dataCy}>
          <CircularProgress
            size={size}
            thickness={5}
            color="primary"
            className={spinnerClassName}
          />
        </Stack>
      );
    }

    return null;
  }
);

Loader.displayName = 'Loader';

Loader.propTypes = {
  className: PropTypes.string,
  spinnerClassName: PropTypes.string,
  'data-cy': PropTypes.string,
  show: PropTypes.bool,
  size: PropTypes.number,
  fullPage: PropTypes.bool,
};

Loader.defaultProps = {
  className: '',
  spinnerClassName: '',
  'data-cy': null,
  show: true,
  size: 80,
  fullPage: true,
};

export default Loader;
