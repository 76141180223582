import strapiApi from '@/helpers/strapi/api';

function getNavbarLink(navbarLink) {
  const attributes = navbarLink.attributes;

  return {
    name: attributes.title,
    href: attributes.link,
    children: getNavbarLinkChildren(navbarLink),
  };
}

function getNavbarLinkChildren(navbarLink) {
  const attributes = navbarLink.attributes;
  const hasChildrenLinks = !!attributes.navbar_links?.data?.length;

  if (!hasChildrenLinks) return [];

  return attributes.navbar_links.data.map(getNavbarLink);
}

export async function fetchNavigation() {
  const { data: navigation } = await strapiApi.getHeaderNavigation();

  const firstLevelLinks = navigation.data.attributes.navbar_links.data;

  const computedNavigation = firstLevelLinks.map(getNavbarLink);

  return computedNavigation;
}

export async function fetchNewNavigation() {
  const { data: navigation } = await strapiApi.getMainMenu();

  return navigation;
}
